@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-custom m-0 osx-font-smoothing;
}

code {
  @apply font-code;
}

.datepick input {
  @apply block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-white border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500  p-2.5 text-sm pl-10 rounded-lg
}