.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button-with-loading {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  row-gap: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.overlay-wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0.4rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  background-color: rgba(128, 128, 128, 0.75);
  border-radius: 0.5rem;
}