.navbars {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #333;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: width 0.3s ease-in-out;
  z-index: 1000;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
}

.burger-icon div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px;
}

@media screen and (max-width: 768px) {
  .navbars {
    width: 60px; /* Collapsed width */
  }

  .navbars.expanded {
    width: 200px; /* Expanded width */
  }

  .burger-icon {
    display: block;
  }

  .nav-links {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .nav-links li {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.nav-links.hide {
  flex: none;
  display: none;
}

.nav-links.show {
  display: flex;
}
